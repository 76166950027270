@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: white;
  }
  
.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

.slick-slide>div{
    margin: 0 10px;
  }

.slick-list>div{
    margin: 0 -10px;
  }
